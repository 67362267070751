/*globals process*/
import React from 'react';
import { withPrismicPreviewResolver } from 'gatsby-plugin-prismic-previews';
import { linkResolver, transformFieldName } from '../utils/prismic-config';

const PreviewPage = () => {
  return ( <p>Loading...</p> );
};

export default withPrismicPreviewResolver(PreviewPage, [
  {
    repositoryName: process.env.PRISMIC_REPOSITORY,
    linkResolver, transformFieldName
  }
]);
